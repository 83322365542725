<template>
  <div class="content">
      <div class="top">
          <div class="title">
              <img style="width: calc(100vw * 370 / 1920);height: calc(100vw * 168 / 1920);" src="@/assets/img3/logo.png"
                  alt="">
          </div>
          <div class="logo">
              <img style="width: calc(100vw * 161 / 1920);height: calc(100vw * 59 / 1920);" src="@/assets/img3/gsLOGO.png"
                  alt="">
          </div>
      </div>

      <div class="main">
          <template v-for="(item, index) in prompt">
              <!-- <div> -->
              <div class="left1" v-if="item.role == 'assistant'">
                  <img style="margin-right: calc(100vw * 30 / 1920);width: calc(100vw * 99 / 1920);height: calc(100vw * 99 / 1920);"
                      src="@/assets/img3/avatar1.png" alt="">
                  <div class="left2">
                      <div class="left" v-html="nr(item.content)">

                      </div>
                  </div>
              </div>

              <div class="right1" v-if="item.role == 'user'">
                  <div class="right2">
                      <div class="right">
                        {{ filtration(item.content) }}
                      </div>
                  </div>
                  <img style="margin-left: calc(100vw * 30 / 1920);width: calc(100vw * 99 / 1920);height: calc(100vw * 99 / 1920);"
                      src="@/assets/img3/avatar2.png" alt="">

              </div>
              <!-- </div> -->

          </template>
          <div class="left1" v-if="event == 'add'">
              <img style="margin-right: calc(100vw * 30 / 1920);width: calc(100vw * 99 / 1920);height: calc(100vw * 99 / 1920);"
                  src="@/assets/img3/avatar1.png" alt="">
              <div class="left2">
                  <div class="left" v-html="markdownToHtml">

                  </div>
              </div>
          </div>

      </div>

      <div class="bottom">
          <!-- <div class="left"> -->
          <img @click.stop="help"
              style="width: calc(100vw * 310 / 1920);height: calc(100vw * 111 / 1920);cursor: pointer;"
              src="@/assets/img3/pop.png" alt="">
          <!-- </div> -->
          <div class="center">
              <img class="tips1" v-if="!Object.keys(prompt).length && !flag" src="@/assets/img3/tips1.png" alt="">
              <img @click="ask" id="startBtn" style="width: calc(100vw * 305 / 1920);height: calc(100vw * 138 / 1920);"
                  v-show="!flag" src="@/assets/img3/voice.png" alt="">
              <div class="zt">语音识别</div>
          </div>
          <!-- <div class="right"> -->
          <img class="clear" @click="clearHistory" :style="{ opacity: Object.keys(prompt).length }"
              src="@/assets/img3/btn.png" alt="">

          <!-- </div> -->
      </div>
      <div class="mask" v-show="flag">
          <div class="mask1">
              <div style="margin-left: calc(100vw * 115 / 1920);">
                  <div class="tips">
                      <!-- <img src="@/assets/img/tips.png" alt="">
            <span>点击确定后即刻反馈结果</span> -->
                      <img src="@/assets/img3/tips.png" alt="">
                  </div>
                  <div class="top">
                      {{ content }}
                      <!-- <div>正在识别中...</div> -->
                      <div class="countdown">{{ countdown }}</div>
                  </div>
                  <div class="btn" id="endBtn">
                      <!-- <img @click="cancelBtn" ref="endBtn" style="width: calc(100vw * 69 / 1920);height: calc(100vw * 28 / 1920);"
              src="@/assets/img/cancel.png" alt="">
            <img @click="confirmBtn" style="width: calc(100vw * 67 / 1920);height: calc(100vw * 28 / 1920);"
              src="@/assets/img/conform.png" alt=""> -->
                      <div @click="cancelBtn" ref="endBtn" style="border-radius: calc(100vw * 30 / 1920);
background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.57) 100%);width: calc(100vw * 124 / 1920);
height: calc(100vw * 54 / 1920);font-size:calc(100vw * 32 / 1920);color: rgba(42, 130, 228, 1);
display: flex;justify-content: center;align-items: center;">取消</div>

                      <div @click="confirmBtn" style="width: calc(100vw * 124 / 1920);
height: calc(100vw * 54 / 1920);
opacity: 1;
border-radius: calc(100vw * 30 / 1920);
background: linear-gradient(180deg, rgba(118, 173, 247, 1) 0%, rgba(86, 152, 252, 1) 100%);font-size: calc(100vw * 32 / 1920);
display: flex;justify-content: center;align-items: center;

color: rgba(255, 255, 255, 1);">确定</div>
                  </div>
              </div>
              <div class="img">
                  <!-- <img style="width: calc(100vw * 114 / 1920);height: calc(100vw * 114 / 1920);" src="@/assets/img1/voice_pic.png" alt=""> -->
                  <img @click.stop="help"
                      style="width: calc(100vw * 310 / 1920);height: calc(100vw * 111 / 1920);opacity: 0;"
                      src="@/assets/img1/pop.png" alt="">
                  <!-- </div> -->
                  <div class="center">
                      <img style="width: calc(100vw * 305 / 1920);height: calc(100vw * 138 / 1920);"
                          src="@/assets/img3/voice_pic.png" alt="">
                      <div class="zt" style="opacity: 0;">1</div>
                  </div>
                  <!-- <div class="right"> -->
                  <img class="clear" @click="clearHistory"
                      style="width: calc(100vw * 199 / 1920);height: calc(100vw * 71 / 1920);opacity: 0;"
                      src="@/assets/img1/btn.png" alt="">
              </div>
          </div>
      </div>
      <div class="maskFlag" v-if="maskFlag">
          <div class="mask_bg">
              <div class="mask_bg1">
                  <div class="mask_bg2">
                      <div class="mask_bg3">
                          <div class="mask1">
                              <div class="img">
                                  <img style="width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);"
                                      src="@/assets/img3/avatar1.png" alt="">
                              </div>
                              <div class="keywords">
                                  <div>可以通过语音向我提问关键词，咨询更多功课知识哦~</div>
                                  <div class="two">如：请告诉我<span>加减乘除</span>的运算方式？</div>
                              </div>
                              <div class="confirm" @click="okBtn">好的</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import minix from '@/minix'
export default {
  mixins: [minix],
  data() {
      return {
          maskFlag: false,
          flag: false,
          eventSource: null,
          message: '',
          content: '',
          signSucc: '',
          prompt: [
          ],
          event: '',
      }
  },
  destroyed() {
      if (this.signSucc) {
          if (this.plays) {
              this.plays.then(res => {
                  this.signSucc.pause();
              })
          }
      }
  },
  mounted() {
      this.audioInit()
  },
  methods: {

  },
}
</script>
<style lang="scss">
@import '@/assets/css/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

.maskFlag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: .6);
    display: flex;
    align-items: center;
    justify-content: center;
  .mask_bg{
    width: calc(100vw * 715 / 1920);
    position: relative;
    opacity: 1;
    border-radius: calc(100vw * 70 / 1920);
background: linear-gradient(180deg, rgba(52, 117, 217, 1) 0%, rgba(91, 150, 227, 1) 100%);
display: flex;
    align-items: center;
    justify-content: center;
  }
  .mask_bg1{
    width: 100%;
    position: relative;
    top: calc(100vw * -9 / 1920);
    border-radius: calc(100vw * 70 / 1920);
background: linear-gradient(180deg, rgba(99, 158, 248, 1) 0%, rgba(118, 173, 247, 1) 100%);
padding: 15% 2% 2% 2%;
  }
  .mask_bg2{
    // margin: 20% 10%;
    border-radius: calc(100vw * 70 / 1920);
background: rgba(170, 202, 255, 1);
box-shadow: 0px calc(100vw * 5 / 1920) calc(100vw * 10 / 1920)  rgba(71, 145, 255, 1);
  }
  .mask_bg3{
    position: relative;
    top: calc(100vw * -9 / 1920);
    border-radius: calc(100vw * 70 / 1920);
background: rgba(255, 255, 255, 1);
display: flex;
    align-items: center;
    justify-content: center;
  }
    .mask1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      opacity: 1;
      position: relative;
      padding: calc(100vw * 80 / 1920) calc(100vw * 10 / 1920) calc(100vw * 40 / 1920) calc(100vw * 10 / 1920);

      .img {
        position: absolute;
        top: -30%;
        left: 50%;
        transform: translate(-50%, 30%);
        border: calc(100vw * 5 / 1920) solid #fff;
        border-radius: calc(100vw * 40 / 1920);
        width: calc(100vw * 117 / 1920);
height:  calc(100vw * 117 / 1920);
display: flex;
justify-content: center;
align-items: center;
background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(189, 234, 255, 1) 100%);
box-shadow: 0px calc(100vw * 20 / 1920) calc(100vw * 30 / 1920)  rgba(59, 168, 204, 0.3),inset 0px 0px calc(100vw * 10 / 1920) calc(100vw * 4 / 1920) rgba(255, 255, 255, 1);
      }
  
      .keywords {
        margin-top: 4%;
        font-weight: 800;
        color: #412C16;
        // font-family: 'shuzhan';
        font-size: calc(100vw * 28 / 1920);
        text-align: center;
        line-height: calc(100vw * 36 / 1920);
        .two {
          font-weight: 800;
          color: #412C16;
          line-height: calc(100vw * 47 / 1920);
  
          span {
            color: #CC501A
          }
        }
      }
  
      .confirm {
        margin-top: 5%;
        width: calc(100vw * 124 / 1920);
height: calc(100vw * 54 / 1920);
border-radius: calc(100vw * 30 / 1920);
background: linear-gradient(180deg, rgba(118, 173, 247, 1) 0%, rgba(86, 152, 252, 1) 100%);
        opacity: 1;
        font-size: calc(100vw * 32 / 1920);
font-weight: 400;
letter-spacing: 0px;

color: rgba(255, 255, 255, 1);
display: flex;
justify-content: center;
align-items: center;

      }
    }
  }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  justify-content: center;

  .mask1 {
      padding: calc(100vw * 45 / 1920) calc(100vw * 87.5 / 1920);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      bottom: 0;

      // transform: translate(-50%, 0);
      .top {
          font-size: calc(100vw * 24 / 1920);
          // font-family: 'shuzhan';
          padding: calc(100vw * 60 / 1920);
          min-width: calc(100vw * 593 / 1920);
          max-width: 100%;
          min-height: calc(100vw * 157 / 1920);
          border-radius: calc(100vw * 30 / 1920);
          background: rgba(174, 216, 251, 1);

          border: calc(100vw * 5 / 1920) solid rgba(255, 255, 255, 1);
          position: relative;
          color: #082348;

          div {
              position: absolute;
              bottom: 0;
              right: 0;
              //   font-family: 'shuzhan';
              padding: calc(100vw * 14 / 1920) calc(100vw * 25 / 1920);
              font-size: calc(100vw * 15 / 1920);
              color: #412C16;
          }
      }

      .btn {
          display: flex;
          justify-content: center;
          align-items: center;

          margin: calc(100vw * 15 / 1920) calc(100vw * 20 / 1920);

          div {
              margin: 0 calc(100vw * 20 / 1920);

          }
      }

      .img {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
      }
  }
}

.content {
  font-family: 'YSHaoShenTi-2';
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  padding: calc(100vw * 45 / 1920) calc(100vw * 87.5 / 1920);
  // padding-bottom: 0;
  background: url('@/assets/img3/underpic.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='bg-login.png', sizingMethod='scale');
}

.top {
  // margin-top: 45px;
  height: 15%;
  width: 100%;
  position: relative;

  .title {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
  }

  .logo {
      position: absolute;
      right: calc(100vw * 30 / 1920);
      top: 0;
  }
}

.main::-webkit-scrollbar {
  display: none;
}

.main {
  flex: 1;
  // height: 80%;
  // font-family: 'shuzhan';
  overflow-y: scroll;

  .left1 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: calc(100vw * 10 / 1920);
  }

  .left2 {
      border-radius: calc(100vw * 15 / 1920) calc(100vw * 15 / 1920) calc(100vw * 15 / 1920) 0px;
      opacity: 1;
      // border: 3px solid #B89774;
      max-width: 40%;

  }

  .left {
      display: flex;
      justify-content: flex-start;
      padding: calc(100vw * 20 / 1920)  calc(100vw * 42 / 1920);
      opacity: 1;
      min-height: calc(100vw * 86 / 1920);
      font-size: calc(100vw * 33 / 1920);

      color: #412C16;
      line-height: calc(100vw * 53 / 1920);
      margin: 2px;
      border-radius: calc(100vw * 40 / 1920) calc(100vw * 40 / 1920) calc(100vw * 40 / 1920) 0;

      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(189, 234, 255, 1) 100%);
      box-shadow: inset 0px 0px calc(100vw * 10 / 1920) calc(100vw * 4 / 1920) rgba(255, 255, 255, 1);
      overflow: auto;
  }

  .right1 {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: calc(100vw * 10 / 1920);

  }

  .right2 {
      border-radius: calc(100vw * 15 / 1920) calc(100vw * 15 / 1920) 0px calc(100vw * 15 / 1920);
      opacity: 1;
      max-width: 40%;
      // border: 3px solid #B89774;
  }

  .right {
      display: flex;
      justify-content: flex-end;
      padding: calc(100vw * 20 / 1920) calc(100vw * 42 / 1920);


      opacity: 1;
      min-height: calc(100vw * 86 / 1920);
      font-size: calc(100vw * 33 / 1920);
      color: #412C16;
      line-height: calc(100vw * 53 / 1920);

      margin: calc(100vw * 2 / 1920);
      border-radius: calc(100vw * 40 / 1920) calc(100vw * 40 / 1920) 0 calc(100vw * 40 / 1920);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(189, 254, 255, 1) 100%);
      box-shadow: inset 0px 0px calc(100vw * 10 / 1920) calc(100vw * 4 / 1920) rgba(255, 255, 255, 1);
  }

  .stream {}
}

.bottom {
  height: calc(100vw * 171 / 1920);
  width: 100%;
  // height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: end;

  .center {
      position: relative;

      .tips1 {
          width: calc(100vw * 342 / 1920);
          height: calc(100vw * 141 / 1920);
          position: absolute;
          top: calc(100vw * -120 / 1920);
          left: 0;
      }
  }

  .clear {
      cursor: pointer;
      width: calc(100vw * 199 / 1920);
      height: calc(100vw * 71 / 1920);
      position: relative;
      bottom: calc(100vw * 40 / 1920);
  }
}

.zt {
  text-align: center;
  font-weight: 800;
  color: #fff;
  line-height: calc(100vw * 31 / 1920);
  // font-family: 'shuzhan';
  font-size: calc(100vw * 27 / 1920);
}

.tips {
  display: flex;
  // font-family: 'sc_regular';
  align-items: baseline;
  position: relative;

  img {
      width: calc(100vw * 447 / 1920);
      height: calc(100vw * 127 / 1920);
      position: absolute;
      top: calc(100vw * -50 / 1920);
      left: 0;
      z-index: 999;
  }

  span {
      color: #FFFFFF;
      line-height: calc(100vw * 37 / 1920);
      font-size: calc(100vw * 20 / 1920);
  }
}

.countdown {
  position: absolute;
  top: 0;
  right: calc(100vw * 20 / 1920);
}</style>