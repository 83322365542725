/*
 * @Description: 
 * @Version: 
 * @Autor: ping
 * @Date: 2023-08-03 20:23:52
 * @LastEditors: ping
 * @LastEditTime: 2023-08-06 23:36:49
 */
import Vue from 'vue'
import App from './App2.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import audio from 'vue-mobile-audio'
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  audio,
  render: h => h(App)
}).$mount('#app')
